import React from "react";
import TextSection from "../templates/textSection";
import { useStaticQuery, graphql } from "gatsby";

const IndustryDay = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            industryday {
              industryday {
                title
                text
              }
            }
          }
        }
      }
    `
  );
  const siteContent = data.site.siteMetadata.industryday;
  const industryday = siteContent.industryday;
  return <TextSection data={industryday} />;
};

export default IndustryDay;
