import React from "react";
import Carousel from "react-multi-carousel";
import { Image, Col, Container } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import "react-multi-carousel/lib/styles.css";
import PropTypes from "prop-types";

const Sponsers = ({ noTitle }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            sponsors {
              sponsors {
                title
                sponsorsData {
                  image
                  link
                }
              }
            }
          }
        }
      }
    `
  );

  const siteContent = data.site.siteMetadata.sponsors;
  const sponsors = siteContent.sponsors;

  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 2,
      paritialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };

  const title = noTitle ? noTitle : sponsors.title;

  return (
    <Container>
      <Col md={12}>
        <h2 className={"text-center section-title"}>{title}</h2>
        <Col sm={12}>
          <Carousel
            itemClass="image-item"
            arrows={true}
            autoPlay={true}
            centerMode={true}
            className=""
            containerClass="carousel"
            focusOnSelect={true}
            infinite={true}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
          >
            {sponsors.sponsorsData.map((data, i) => {
              return (
                <a href={data.link} key={i}>
                  <Image draggable={false} src={data.image} />
                </a>
              );
            })}
          </Carousel>
        </Col>
      </Col>
    </Container>
  );
};

Sponsers.propTypes = {
  noTitle: PropTypes.string
};

export default Sponsers;
