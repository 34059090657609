import React from "react";
import TextSection from "../templates/textSection";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

const Contact = ({ noTitle }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contact {
              contact {
                title
                text
              }
            }
          }
        }
      }
    `
  );
  const siteContent = data.site.siteMetadata.contact;
  const contact = siteContent.contact;
  const title = noTitle ? noTitle : contact.title;
 
  return (
    <section id={"Contact"}>
    <TextSection data={{...contact, title}} />;
    </section>
  );
};

Contact.propTypes = {
  noTitle: PropTypes.string
};

export default Contact;
