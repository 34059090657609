import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import About from "./about";
import KeyDates from "./keyDates";
import ConferenceTopics from "./conferenceTopics";
import Committee from "./committee";
import KeySpeakers from "./keySpeakers";
import Submissions from "./submissions";
import Workshops from "./workshops";
import Programme from "./programme";
import Venue from "./venue";
import Registration from "./registration";
import News from "./news";
import Hackathon from "./hackathon";
import Sponsors from "./sponsors";
import Assembly from "./assembly";
import IndustryDay from "./industryDay";

export default () => {
  return (
    <Container className={"content"}>
      <Row>
        <Col sm={12}>
          <section id={"News"}>
            <News />
          </section>

          <section id={"About"}>
            <About />
          </section>

          <section id={"Registration"}>
            <Registration />
          </section>

          <section id={"Programme"}>
            <Programme />
          </section>

          <section id={"IndustryDay"}>
            <IndustryDay />
          </section>

          <section id={"Speakers"}>
            <KeySpeakers />
          </section>

          <section id={"Workshops"}>
            <Workshops />
          </section>

          <section id={"Committee"}>
            <Committee />
          </section>

          <section id={"Submissions"}>
            <Submissions />
          </section>

          <section id={"Topics"}>
            <ConferenceTopics />
          </section>

          <section id={"KeyDates"}>
            <KeyDates />
          </section>

          <section id={"Venue"}>
            <Venue />
          </section>

          <section id={"Assembly"}>
            <Assembly />
          </section>

          <section id={"Sponsors"}>
            <Sponsors />
          </section>
        </Col>
      </Row>
    </Container>
  );
};
