import React, { useState } from "react";
import PropTypes from "prop-types";
import Showdown from "showdown";
import { Container, Row, Col } from "react-bootstrap";
import ModalPopup from "./modalPopup";

const ProfileDataSection = ({ data }) => {
  const [modalId, setModalId] = useState("");

  const convertMarkDown = new Showdown.Converter();
  const returnHTMLData = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: convertMarkDown.makeHtml(data.text),
        }}
      />
    );
  };
  const handleClose = () => setModalId("");
  return (
    <Container>
      <h2 className={"text-center section-title"}>{data.title}</h2>
      {returnHTMLData()}
      {data.profileData &&
        data.profileData[0].name &&
        data.profileData.map(
          ({ image, name, topic, abstract, biography }, i) => (
            <Row key={i}>
              <Col xs={12} key={i} className={"profileData"}>
                <h4>
                  {name}
                  &nbsp;
                  <a onClick={() => setModalId(`modal${i}`)} style={{cursor:'pointer'}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="blue"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                      data-tip
                      data-for="biography"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </a>
                </h4>
                <hr></hr>
                <ModalPopup
                  modalShow={modalId === `modal${i}`}
                  body={biography}
                  onHide={handleClose}
                />
              </Col>
              <Col sm={8} md={4}>
                <img
                  className="biography_image img-responsive"
                  src={`${image}`}
                />
              </Col>
              <Col sm={12} md={8}>
                <h4>Topic:</h4>
                <p>{topic}</p>

                <h4>Abstract:</h4>
                <p>{abstract}</p>
              </Col>
            </Row>
          )
        )}
    </Container>
  );
};

ProfileDataSection.propTypes = {
  data: PropTypes.object,
};

export default ProfileDataSection;
