import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";
import Showdown from "showdown";
import { Container, Col } from "react-bootstrap";

const TableAndTextSection = ({ data }) => {
  const convertMarkDown = new Showdown.Converter();
  const returnHTMLData = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: convertMarkDown.makeHtml(data.text),
        }}
      />
    );
  };
  const returnSubTextHTMLData = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: convertMarkDown.makeHtml(data.subtext),
        }}
      />
    );
  };
  return (
    <Container>
      <Col xs={12}>
      <h2 className={"text-center section-title"}>{data.title}</h2>
      {returnSubTextHTMLData()}
      {data.table &&
      <Table striped bordered hover>
        <thead>
          <tr>
            {data.table.heading &&
              data.table.heading.map(({ title }, i) => (
                <th key={i}>{title}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.table.column &&
            data.table.column.map(({ columnData }, i) =>
              columnData.map(({ row }, i) => (
                <tr key={i}>
                  {row.map(({ data }, i) => (
                    <td key={i}>{data}</td>
                  ))}
                </tr>
              ))
            )}
        </tbody>
      </Table>}
      <div>{returnHTMLData()}</div>
        </Col>
   
    </Container>
  );
};

TableAndTextSection.propTypes = {
  data: PropTypes.object,
};
export default TableAndTextSection;
