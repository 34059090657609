import React, { useState } from "react";
import TextSection from "../templates/textSection";
import { useStaticQuery, graphql } from "gatsby";
import { Document, Page, pdfjs } from "react-pdf";
import { Row, Col, Container } from "react-bootstrap";

const Programme = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.5);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [renderedScale, setRenderedScale] = useState(null);

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const changeScale = (offset) => {
    setScale((prevScale) => prevScale + offset);
  };

  const decreaseScale = () => {
    changeScale(-0.1);
  };

  const increaseScale = () => {
    changeScale(0.1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const isLoading =
    renderedPageNumber !== pageNumber || renderedScale !== scale;

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            programme {
              programme {
                title
                text
              }
            }
          }
        }
      }
    `
  );

  const ProgrammeDoc = () => {
    return (
      <Document
        file="/assets/ProgrammeV1.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
        className={"PDFDocument"} 
      >
        {isLoading && renderedPageNumber && renderedScale ? (
          <Page
            key={renderedPageNumber + "@" + renderedScale}
            pageNumber={renderedPageNumber}
            scale={renderedScale}
            className={"prevPage PDFPage"}
          />
        ) : null}
        <Page
          key={pageNumber + "@" + scale}
          pageNumber={pageNumber}
          onRenderSuccess={() => {
            setRenderedPageNumber(pageNumber);
            setRenderedScale(scale);
          }}
          scale={scale}
          className={"PDFPage"}
        />
      </Document>
    );
  };

  const siteContent = data.site.siteMetadata.programme;
  const programme = siteContent.programme;
  return (
    <Container>
      <TextSection data={programme} />
      <Row>

        <Col md={4} className="d-none d-md-block">
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </button>{" "}
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
          <a
            href="/assets/ProgrammeV1.pdf"
            download="newfilename"
            style={{ marginLeft: "10px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </a>
        </Col>
        <Col md={4} className="d-none d-md-block" align="center">
          <p style={{ textAlign: 'center'}}>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
        </Col>
        <Col md={4} className="d-none d-md-block" align="center">
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={scale <= 0.5}
            onClick={decreaseScale}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-zoom-out"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
              <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
              <path
                fillRule="evenodd"
                d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>{" "}
          <button
            type="button"
            disabled={scale >= 5}
            className="btn btn-outline-dark"
            onClick={increaseScale}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-zoom-in"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
              <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
              <path
                fillRule="evenodd"
                d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
          </button>
        </Col>

        <Col xs={6} className="d-block d-md-none">
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
          </button>{" "}
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </button>
          <a
            href="/assets/ProgrammeV1.pdf"
            download="newfilename"
            style={{ marginLeft: "10px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
          </a>
        </Col>
        <Col xs={4} className="d-block d-md-none">
          <p>
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </p>
        </Col>
        <Col xs={10} className="d-block d-md-none">
          <button
            type="button"
            className="btn btn-outline-dark"
            disabled={scale <= 0.5}
            onClick={decreaseScale}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-zoom-out"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
              <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
              <path
                fillRule="evenodd"
                d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>{" "}
          <button
            type="button"
            disabled={scale >= 5}
            className="btn btn-outline-dark"
            onClick={increaseScale}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-zoom-in"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              />
              <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
              <path
                fillRule="evenodd"
                d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
          </button>
        </Col>
      </Row>
      <Container>
        <div className="pdf-viewer">
          <ProgrammeDoc />
        </div>
      </Container>
    </Container>
  );
};

export default Programme;
