import React from "react";
import TextSection from "../templates/textSection";
import { useStaticQuery, graphql } from "gatsby";

const Venue = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            venue {
              venue {
                title
                text
              }
            }
          }
        }
      }
    `
  );
  const siteContent = data.site.siteMetadata.venue;
  const venue = siteContent.venue;
  return <TextSection data={venue} />;
};

export default Venue;
