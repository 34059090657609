import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

const ModalPopup = ({ body, onHide, modalShow }) => {
  return (
    <Modal
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ top: "20px" }}
    >
      <ModalBody>
        <p>{body}</p>
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-secondary" onClick={onHide}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

ModalPopup.propTypes = {
  body: PropTypes.string,
  onHide: PropTypes.func,
  modalShow: PropTypes.bool,
};

export default ModalPopup;
