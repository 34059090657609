import React, { Fragment, useEffect } from "react";
import Helmet from "react-helmet";
import HomepageBanner from "../components/homepageBanner";
import SiteFooterMap from "../components/siteFooterMap";
import Contact from "../components/contact";
import "bootstrap/dist/css/bootstrap.min.css";
import { graphql } from "gatsby";
import HomepageContent from "../components/homepageContent";

const IndexPage = ({ data: { site } }) => {
  const { siteMetadata } = site;

  const scrollToHashId = () => {
    // get URL hash (minus the hash mark)
    const hash = window.location.hash.substring(1);
     // if there's a hash, scroll to that ID
     if (hash && hash.length) {
      // setTimeout and requestAnimationFrame help ensure a true DOM repaint/reflow before we try to scroll
      // - reference: http://stackoverflow.com/a/34999925
      setTimeout(
        window.requestAnimationFrame(function () {
          const el = document.getElementById(hash)
          el.scrollIntoView()
          // clean up the hash, so we don't scroll on every prop update
          removeHash()
        }),
        0
      )
    }
  }
   // borrowed from http://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-with-javascript-without-page-refresh/5298684#5298684
  const removeHash = () => {
    const loc = window.location
    const hist = window.history

    // use modern browser history API
    if (hist && 'pushState' in hist) {
      hist.replaceState('', document.title, loc.pathname + loc.search)
    // fallback for older browsers
    } else {
      // prevent scrolling by storing the page's current scroll offset
      const scrollV = document.body.scrollTop
      const scrollH = document.body.scrollLeft

      loc.hash = ''

      // restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV
      document.body.scrollLeft = scrollH
    }
  }

  useEffect(() => {
    scrollToHashId();
  },[]);

  return (
    <Fragment>
      <Helmet>
        <title>European Conference On Product And Process Modelling 2022</title>
        <link rel="canonical" href="https://www.ecppm2022.org/" />
      </Helmet>
        <HomepageBanner
          homeBannerContent={siteMetadata.homePageBanner.homeBannerContent}
          menuItems={siteMetadata.homePageBanner.menuItems}
        />
        <HomepageContent />
        <Contact />
        <SiteFooterMap menuItems={siteMetadata.homePageBanner.menuItems} displayMap={true}/>
    </Fragment>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        homePageBanner {
          menuItems {
            menuItem
          }
          homeBannerContent {
            title
            description
            image
            bannerButtonText
            bannerbuttonLink
          }
        }
      }
    }
  }
`;
