import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ProfileDataSection from "../templates/profileDataSection";

const Assembly = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            assembly {
              assembly {
                title
                subtext
                profileData {
                  image
                  title
                  text
                }
                text
              }
            }
          }
        }
      }
    `
  );
  const siteContent = data.site.siteMetadata.assembly;
  const assembly = siteContent.assembly;
  return <ProfileDataSection data={assembly} />;
};

export default Assembly;
