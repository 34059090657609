import React from "react";
import TextSection from "../templates/textSection";
import { useStaticQuery, graphql } from "gatsby";

const Registration = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            registration {
              registration {
                title
                text
              }
            }
          }
        }
      }
    `
  );
  const siteContent = data.site.siteMetadata.registration;
  const registration = siteContent.registration;
  return <TextSection data={registration} />;
};

export default Registration;
